interface MetaData {
  alt: string;
  title: string;
  width: number;
  height: number;
}

export interface Location {
  bucket: string;
  path?: string | null;
}

export interface CmsMedia {
  name?: string;
  type?: string;
  metaData: MetaData;
  location: Location;
  source?: string; // FIXME: likely needed because an inconsistency in the API
}

interface VideoMetaData {
  thumbnailUrl: string;
  lengthInSeconds: number;
}

export interface VideoMedia extends Omit<CmsMedia, 'metaData'> {
  source: string;
  uuid: string;
  metaData: VideoMetaData;
}

export function useCmsMedia () {
  const getImageUrl = (
    media: CmsMedia,
    options?:  {
      width?: number;
      height?: number;
      output?: string;
    }) => {
    const baseUrl = useRuntimeConfig().public.imageServiceUrl.replace(/\/$/, '');

    const config = {
      width: media.metaData.width,
      height: media.metaData.height,
      output: 'webp',
    };

    if (options) { Object.assign(config, options); }

    const configBase64 = btoa(JSON.stringify(config));
    const bucket = media.location.bucket;
    const path = media.location.path;

    return `${baseUrl}/${bucket}/${path}?cfg=${configBase64}`;
  };

  return {
    getImageUrl,
  };
}
